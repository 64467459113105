import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GameComponent } from './game/game.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public dialog: MatDialog) {}

  openGameWindow(): void {
    this.dialog.open(GameComponent, {
      minHeight: "720px",
      minWidth: "1690px"
    });
  }
  
}
