import { Component } from '@angular/core';


@Component({
  template: ``
})
export class TableDataComponent {
  name: string;
  time: number;
  lives: number;

}
