import { Component, OnInit } from '@angular/core';
import { TableDataComponent } from '../table-data/table-data.component';
import { HttpClient } from '@angular/common/http';

declare const UnityLoader: any;
declare const UnityProgress: any;

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {
  players: TableDataComponent[];
  displayedColumns: string[] = ['name', 'time', 'lives'];
  configUrl: string = "https://asimplerouter.go.ro/index_files/scor.php";

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get<TableDataComponent[]>(this.configUrl).subscribe(
      (data: TableDataComponent[]) => {
        this.players = data;
      }
    );

    UnityLoader.instantiate("gameContainer", "../../assets/unity/scripts/html5.json", {onProgress: UnityProgress});
  }

}
